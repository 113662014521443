<template>
  <div class="layer_02">
    <!-- layer_top -->
    <div class="layer_top">
      <h2 class="layer_top_title">달성 가능한 미션</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <!-- //layer_top -->
    <!-- achievement_list -->
    <div class="achievement_list">
      <form>
        <table>
          <colgroup>
            <col style="width: 168px" />
            <col style="width: 174px" />
            <col style="width: 270px" />
          </colgroup>
          <tr>
            <th class="layer_list_title">선택</th>
            <th class="layer_list_title">구분</th>
            <th class="layer_list_title">미션 명</th>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>리워드</td>
            <td>기차여행객</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>서울의 달</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>조선의 궁궐에 당도한 것을 환영하오</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>리워드</td>
            <td>편안하고 소통할 수 있는 나들이</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>BTS 힐링 성지, 5경 스며들기</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>완주 6경</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>완주 9경</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>리워드</td>
            <td>그냥버스</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>서울의 달</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>역무원</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>리워드</td>
            <td>완주 3경</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>오빠차뽑았다</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>역사와 멋 정복, 의정부 마스터</td>
          </tr>
          <tr class="layer_list_box">
            <td>
              <input type="radio" class="list_radio" />
            </td>
            <td>배지</td>
            <td>역사와 멋 정복, 의정부 마스터</td>
          </tr>
        </table>
      </form>
    </div>
    <!-- //achievement_list -->
    <div class="btn_center_wrapper">
      <base-button type="primary"> 적용 </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
};
</script>
