<template>
  <div class="layer_01">
    <div class="layer_top">
      <h2 class="layer_top_title">
        {{ status == 1 ? "일반 스탬프" : "리워드 스탬프" }}
        {{ id ? "수정" : "등록" }}
      </h2>
      <img
        src="../assets/img/close.png"
        style="width: 15px; height: 15px"
        alt="닫기"
        @click="$emit('close')"
      />
    </div>
    <div class="layer_01_box">
      <form action="#">
        <table class="layer_01_table">
          <colgroup>
            <col style="width: 107px" />
            <col style="width: 535px" />
            <col style="width: 107px" />
            <col style="width: 505px" />
          </colgroup>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">스탬프 명</td>
            <td class="pt_20 pb_15">
              <input type="text" class="text_box" v-model="name" />
            </td>
            <td class="layer_tbl_title pb_15">지역</td>
            <td class="pb_15">
              <div class="area_select_box_02">
                <base-select
                  :options="areas"
                  v-model="region"
                  placeholder="지역 선택"
                />
              </div>
            </td>
          </tr>
          <tr v-if="status == 1" class="layer_tbl">
            <td class="layer_tbl_title pb_15" ref="refTd">주소</td>
            <td class="pb_15" colspan="3">
              <input
                type="text"
                class="address_box_03"
                readonly
                v-model="address"
              />
              <base-button type="upload" @click="searchAddress('address')"
                >주소찾기</base-button
              >
              <base-button type="upload" class="gps_btn"
                >GPS로 주소찾기</base-button
              >
              <div style="width: 81%; height: auto" ref="refMap"></div>
            </td>
          </tr>
          <tr v-if="status == 1" class="layer_tbl">
            <td class="layer_tbl_title pb_15">위도</td>
            <td class="pb_15">
              <input type="text" class="text_box" v-model="lat" readonly />
            </td>
            <td class="layer_tbl_title pb_15">경도</td>
            <td class="pb_15">
              <input type="text" class="text_box" v-model="lng" readonly />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">운영기간</td>
            <td class="pb_15">
              <date-picker
                v-model="periodStartDay"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
              <span class="ml_04 mr_04">~</span>
              <date-picker
                v-model="periodEndDay"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
            </td>
            <td class="layer_tbl_title pb_15" v-if="status == 1">
              재도전 횟수
            </td>
            <td class="pb_15" v-if="status == 1">
              <input
                type="number"
                :onInput="
                  relationLevel > 999 ? (relationLevel = 999) : relationLevel
                "
                min="1"
                max="999"
                maxlength="3"
                class="lookfor_box"
                v-model="relationLevel"
              />
              <!-- <base-select
                :options="relationLevels"
                v-model="relationLevel"
                placeholder="재도전 레벨 선택"
              /> -->
            </td>
          </tr>
          <tr v-if="status == 1" class="layer_tbl">
            <td class="layer_tbl_title pb_15">GPS 획득</td>
            <td class="pb_15">
              <input
                type="radio"
                id="gps_unobtainable"
                class="radio_input ml_0"
                value="0"
                v-model="gpsState"
              />
              <label for="gps_unobtainable" class="check_label_02"
                >획득 불가능</label
              >
              <input
                type="radio"
                id="gps_obtainable"
                class="radio_input"
                value="1"
                v-model="gpsState"
              />
              <label for="gps_obtainable" class="check_label_02"
                >획득 가능</label
              >
            </td>
            <td class="big_box_title pb_15 pt_04">QR Code 획득</td>
            <td class="qrdown_link_container pb_15">
              <div>
                <input
                  type="radio"
                  id="qrcode_unobtainable"
                  class="radio_input ml_0"
                  value="0"
                  v-model="qrcodeState"
                />
                <label for="qrcode_unobtainable" class="check_label_02"
                  >획득 불가능</label
                >
                <input
                  type="radio"
                  id="qrcode_obtainable"
                  class="radio_input"
                  value="1"
                  v-model="qrcodeState"
                />
                <label for="qrcode_obtainable" class="check_label_02"
                  >획득 가능</label
                >
              </div>
              <a
                :href="qrImgLink"
                target="_blank"
                :download="`${name}`"
                v-if="qrcodeState == 1 && id"
                class="qrdown_link"
              >
                <vue-qrious
                  :size="1000"
                  v-model="qrId"
                  ref="refQRImg"
                  style="display: none"
                />
                QR Code 다운로드
              </a>
            </td>
          </tr>
          <tr class="layer_tbl" v-if="status == 1 && gpsState == 1">
            <td class="layer_tbl_title pb_15" ref="refTd">GPS 주소</td>
            <td class="pb_15" colspan="3">
              <base-button type="upload-lg" @click="searchAddress('gpsAddress')"
                >위도/경도 찾기</base-button
              >
              <div style="width: 81%; height: auto" ref="refGpsMap"></div>
            </td>
          </tr>
          <tr class="layer_tbl" v-if="status == 1 && gpsState == 1">
            <td class="layer_tbl_title pb_15">GPS 위도</td>
            <td class="pb_15">
              <input type="text" class="text_box" v-model="gpsLat" readonly />
            </td>
            <td class="layer_tbl_title pb_15">GPS 경도</td>
            <td class="pb_15">
              <input type="text" class="text_box" v-model="gpsLng" readonly />
            </td>
          </tr>
          <tr>
            <td class="layer_tbl_title pb_15">반경</td>
            <td class="pb_15">
              <input type="text" class="text_box" v-model="gpsRadius" />
              <span class="mr_10 ml_10 text_m">M</span>
            </td>
            <td class="layer_tbl_title pb_15" v-if="status == 1">
              스탬프 레벨
            </td>
            <td class="pb_15" v-if="status == 1">
              <base-select :options="levels" v-model="level" />
            </td>
          </tr>
          <tr>
            <td class="layer_tbl_title pb_15">스탬프</td>
            <td class="pb_15 standard">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="picture"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="picture_upload"
                ref="refPicture"
                @change="previewFile('picture')"
              />
              <label for="picture_upload" class="file_btn">찾아보기</label>
              <div
                v-if="selectFile.picture || picture"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ picture }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('picture')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
            <td class="layer_tbl_title pb_15">운영기관</td>
            <td class="pb_15" style="position: relative">
              <input
                type="text"
                class="text_box_05"
                readonly
                v-model="userName"
              />
              <!-- XXX 운영기관 삭제 임시 -->
              <span
                style="
                  position: absolute;
                  left: 260px;
                  top: 17px;
                  cursor: pointer;
                "
              >
                <img
                  src="../assets/img/close.png"
                  style="width: 10px; height: 10px"
                  alt="닫기"
                  @click="userName = ''"
                />
              </span>
              <base-button type="upload" class="ml_30" @click="callUser"
                >불러오기</base-button
              >
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">짧은 설명</td>
            <td colspan="3" class="pb_15">
              <textarea
                class="textarea_style"
                style="resize: none"
                v-model="shortDescription"
              ></textarea>
            </td>
          </tr>
          <tr>
            <td class="big_box_title pb_15">긴 설명</td>
            <td colspan="3" class="pb_15">
              <textarea
                class="textarea_style"
                style="resize: none"
                v-model="description"
              ></textarea>
            </td>
          </tr>
          <h3 style="margin-top: 30px">(구)스탬프 팝</h3>
          <tr>
            <td class="layer_tbl_title pb_15">스탬프 커버</td>
            <td class="pb_15 standard">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="cover"
                readonly
              />
              <input
                type="file"
                style="display: none"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="cover_upload"
                ref="refCover"
                @change="previewFile('cover')"
                class="lookfor_box mr_30"
              />
              <label for="cover_upload" class="file_btn">찾아보기</label>
              <div
                v-if="selectFile.cover || cover"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ cover }}</span>
                <div
                  class="cancel_img"
                  @click.prevent.stop="removeFile('cover')"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="edit">{{
            id ? "수정" : "등록"
          }}</base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
import MissionCall from "@/components/MissionCall.vue";
import Dialog from "@/components/Dialog.vue";
import VueQrious from "vue-qrious";
import ClientCall from "@/components/ClientCall.vue";

export default {
  components: {
    BaseButton,
    VueQrious,
    BaseSelect,
  },
  props: {
    status: Number,
    id: Number,
  },
  data() {
    return {
      relationLevels: [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
      ],
      relationLevel: 1,
      levels: [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 },
      ],
      areas: [
        { name: "전체", value: "전체" },
        { name: "서울특별시", value: "서울특별시" },
        { name: "경기도", value: "경기도" },
        { name: "강원도", value: "강원도" },
        { name: "인천광역시", value: "인천광역시" },
        { name: "광주광역시", value: "광주광역시" },
        { name: "대구광역시", value: "대구광역시" },
        { name: "대전광역시", value: "대전광역시" },
        { name: "울산광역시", value: "울산광역시" },
        { name: "부산광역시", value: "부산광역시" },
        { name: "세종시", value: "세종시" },
        { name: "제주도", value: "제주도" },
        { name: "세종시", value: "세종시" },
        { name: "충청북도", value: "충청북도" },
        { name: "충청남도", value: "충청남도" },
        { name: "전라북도", value: "전라북도" },
        { name: "전라남도", value: "전라남도" },
        { name: "경상북도", value: "경상북도" },
        { name: "경상남도", value: "경상남도" },
      ],
      level: "",
      name: "",
      address: "",
      region: "",
      periodStartDay: this.$date().format("YYYY-MM-DD"),
      periodEndDay: this.$date().add(1, "year").format("YYYY-MM-DD"),
      lat: "",
      lng: "",
      gpsLat: "",
      gpsLng: "",
      picture: "",
      cover: "",
      gpsRadius: "",
      description: "",
      shortDescription: "",
      userName: "",
      userId: "",

      selectFile: {
        picture: null,
        cover: null,
      },
      gpsState: 1,
      qrcodeState: 0,
      qrId: "",
      qrImgLink: "",
      user: {
        name: "",
        email: "",
        id: "",
      },
    };
  },
  created() {
    if (this.id) {
      this.fetch();
    }
  },
  // watch: {
  //   userName() {
  //     if (!this.userName) {
  //       this.userId = "";
  //     }
  //   },
  // },
  methods: {
    async fetch() {
      await client.stampFindOne(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.name = data.name;
          this.region = data.region;
          this.address = data.content.address;
          data.periodStartDay == "0000-00-00 00:00:00"
            ? (this.periodStartDay = "")
            : (this.periodStartDay = this.$date(data.periodStartDay).format(
                "YYYY-MM-DD"
              ));
          data.periodEndDay == "0000-00-00 00:00:00"
            ? (this.periodEndDay = "")
            : (this.periodEndDay = this.$date(data.periodEndDay).format(
                "YYYY-MM-DD"
              ));
          this.userName = this.id && data.user ? data.user.name : "";
          this.userId = this.id && data.user.id ? data.user.id : "";
          this.relationLevel = data.relationLevel;
          this.level = data.level;
          this.lat = data.lat;
          this.lng = data.lng;
          this.gpsLat = data.gpsLat;
          this.gpsLng = data.gpsLng;
          this.picture = data.picture.name;
          this.cover = data.cover.name;
          this.gpsRadius = data.gpsRadius;
          this.description = data.content.description;
          this.shortDescription = data.content.shortDescription;
          this.gpsState = data.gpsState;
          this.qrcodeState = data.qrcodeState;
          this.qrId = `https://stamppop.com/app/qr/acquisition/${data.id}`;
        },
        (error) => {
          console.log(error);
        }
      );
      if (this.qrcodeState == 1) {
        let refQRImg = this.$refs.refQRImg;
        this.qrImgLink = refQRImg.dataUrl;
      }
    },
    removeFile(type) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectFile[type]) {
                // 로컬에서 선택한 파일이 있을경우
                this.selectFile[type] = null;
              }

              if (type == "picture") {
                this.picture = "";
              }
              if (type == "cover") {
                this.cover = "";
              }
            }
          },
        }
      );
    },
    previewFile(type) {
      // console.log(event);
      // console.log(type);
      // console.log(this.$refs);
      let selectFile = null;
      if (type == "picture") {
        selectFile = this.$refs.refPicture;
      } else if (type == "cover") {
        selectFile = this.$refs.refCover;
      }
      if (0 < selectFile.files.length) {
        this.selectFile[type] = selectFile.files[0];
        let fileExt = this.selectFile[type].name.substring(
          this.selectFile[type].name.lastIndexOf(".") + 1
        );
        // 소문자로 변환
        fileExt = fileExt.toLowerCase();
        // 이미지 확장자 체크, 1메가 바이트 이하 인지 체크
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectFile[type].size <= 1048576
        ) {
          if (type == "picture") {
            this.picture = this.selectFile["picture"].name;
          } else if (type == "cover") {
            this.cover = this.selectFile["cover"].name;
          }
          console.log(this.selectFile[type].name);
          console.log("정상");
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile[type] = null;
        }
      } else {
        // 파일을 선택하지 않았을때
        this.selectFile[type] = null;
      }
    },
    async edit() {
      if (this.selectFile.picture) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.picture);
        await client.fileAdd(form).then(
          (response) => {
            this.picture = response.data.name;
            console.log(response.data.name);
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (this.selectFile.cover) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.cover);
        await client.fileAdd(form).then(
          (response) => {
            this.cover = response.data.name;
            console.log(response.data.name);
          },
          (error) => {
            console.log(error);
          }
        );
      }

      let params = {
        status: this.status,
        name: this.name,
        picture: this.picture,
        cover: this.cover,
        region: this.region,
        content: {
          title: this.name,
          address: this.address,
          description: this.description,
          shortDescription: this.shortDescription,
        },
        periodStartDay: this.periodStartDay,
        periodEndDay: this.periodEndDay,
        relationLevel: this.relationLevel,
        level: this.level,
        lat: this.lat,
        lng: this.lng,
        gpsLat: Number(this.status) == 1 ? this.gpsLat : "",
        gpsLng: Number(this.status) == 1 ? this.gpsLng : "",
        gpsRadius: Number(this.status) == 1 ? this.gpsRadius : 0,
        gpsState: this.gpsState,
        qrcodeState: this.qrcodeState,
        userId: this.userName ? this.userId : "",
      };
      if (!this.name) {
        alert("스탬프 명을 입력해주세요");
      } else if (!this.region) {
        alert("지역을 선택해주세요");
      } else if (this.status == 1 && !this.address) {
        alert("주소를 입력해주세요");
      } else if (this.status == 1 && (!this.lat || !this.lng)) {
        alert("위도, 경도를 입력해주세요");
      } else if (this.status == 1 && (!this.gpsLat || !this.gpsLng)) {
        alert("GPS 위도, 경도를 입력해주세요");
      } else if (this.status == 1 && !this.gpsRadius) {
        alert("반경을 입력해주세요");
      } else if (!this.userName) {
        alert("운영기관을 입력해주세요");
      } else if (this.status == 1 && !this.level) {
        alert("스탬프 레벨을 선택해주세요");
      } else {
        if (this.id) {
          await client.stampModify(this.id, params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          await client.stampAdd(params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
    callMission() {
      this.$modal.show(
        MissionCall,
        {},
        {
          width: 672,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },

    async fetchUserId(userId) {
      this.userId = userId.userId;
      await client.userFindone(this.userId).then((response) => {
        const { data } = response;
        this.userName = data.name;
      });
    },

    callUser() {
      this.$modal.show(
        ClientCall,
        {
          id: this.id,
          userId: this.userId,
        },
        {
          width: 804,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              this.fetchUserId(response.params);
            }
          },
        }
      );
    },
    searchAddress(type) {
      let mapContainer =
          type == "address" ? this.$refs.refMap : this.$refs.refGpsMap, // 지도를 표시할 div
        mapOption = {
          center: new window.daum.maps.LatLng(37.537187, 127.005476), // 지도의 중심좌표
          level: 5, // 지도의 확대 레벨
        },
        refTd = this.$refs.refTd;
      new window.daum.Postcode({
        oncomplete: (data) => {
          //주소-좌표 변환 객체를 생성
          let geocoder = new window.daum.maps.services.Geocoder();
          if (type == "address") {
            this.address = data.address; // 주소 정보를 해당 필드에 넣는다.
            this.address +=
              data.buildingName !== "" || data.apartment == "Y"
                ? " " + data.buildingName
                : "";
          }
          geocoder.addressSearch(data.address, (results, status) => {
            // 정상적으로 검색이 완료됐으면
            if (status === window.daum.maps.services.Status.OK) {
              let result = results[0]; //첫번째 결과의 값을 활용
              //지도를 미리 생성
              let map = new window.daum.maps.Map(mapContainer, mapOption);
              //마커를 미리 생성
              let marker = new window.daum.maps.Marker({
                position: new window.daum.maps.LatLng(37.537187, 127.005476),
                map: map,
              });
              // 해당 주소에 대한 좌표를 받아서
              let coords = new window.daum.maps.LatLng(result.y, result.x);
              if (type == "address") {
                this.lat = coords.Ma;
                this.lng = coords.La;
              } else if (type == "gpsAddress") {
                this.gpsLat = coords.Ma;
                this.gpsLng = coords.La;
              }

              // 지도를 보여준다.
              mapContainer.style.display = "block";
              mapContainer.style.height = "300px";
              mapContainer.style.marginTop = "15px";
              refTd.style.verticalAlign = "top";
              refTd.style.paddingTop = "12px";

              map.relayout();
              // 지도 중심을 변경한다.
              map.setCenter(coords);
              // 마커를 결과값으로 받은 위치로 옮긴다.
              marker.setPosition(coords);

              window.daum.maps.event.addListener(map, "click", (mouseEvent) => {
                let latlng = mouseEvent.latLng;
                marker.setPosition(latlng);
                if (type == "address") {
                  this.lat = latlng.getLat();
                  this.lng = latlng.getLng();
                } else if (type == "gpsAddress") {
                  this.gpsLat = latlng.getLat();
                  this.gpsLng = latlng.getLng();
                }
              });
            }
          });
        },
      }).open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/common.scss";
</style>
